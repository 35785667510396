document.addEventListener("DOMContentLoaded", () => {
  const body = document.querySelector("body");
  const header = document.querySelector("header");
  const mobileMenu = document.querySelector(".js-mob-menu");
  const menuBtn = document.querySelector(".js-menu-btn");
  const navButtons = document.querySelectorAll(".js-nav-btn");

  menuBtn.addEventListener("click", () => {
    if (body.classList.contains("isMobMenuOpen")) {
      handleMenuClose();
    } else {
      handleMenuOpen();
    }
  });

  function scrollToAnchor(anchorId) {
    const anchor = document.getElementById(anchorId);
    const pos = anchor.getBoundingClientRect().top + window.scrollY - 55
    if (anchor) {
      window.scrollTo({
        top: pos,
        behavior: "smooth",
      })
    }
  }

  for (let button of navButtons) {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      const targetId = button.getAttribute("href").substr(1);
      scrollToAnchor(targetId);
      handleMenuClose();
    });
  }

  const handleMenuOpen = () => {
    mobileMenu.classList.add("isMobMenuOpen");
    body.classList.add("isMobMenuOpen");
    header.classList.add("isMobMenuOpen");
    menuBtn.classList.add("isMobMenuOpen");
  };

  const handleMenuClose = () => {
    mobileMenu.classList.remove("isMobMenuOpen");
    body.classList.remove("isMobMenuOpen");
    header.classList.remove("isMobMenuOpen");
    menuBtn.classList.remove("isMobMenuOpen");
  };
});
